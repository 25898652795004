/* Basic styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f6f8;
}

.app-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Header */
.header h4 {
  font-size: 2rem;
  color: #333;
  font-weight: bold;
}

/* Redesigned Prompt Input Field */
.prompt-container {
  margin-bottom: 20px;
}

.prompt-container .MuiOutlinedInput-root {
  border-radius: 50px;
  background-color: #fff;
}

.prompt-container .MuiButton-root {
  border-radius: 50px;
  font-size: 1.2rem;
}

/* Redesigned Response Section */
.response-section {
  border-radius: 12px;
  background-color: #f7f8fa;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 0 auto;
  min-height: 100px;
  padding: 15px;
  transition: all 0.3s ease; /* Smooth transition for dynamic resizing */
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%; /* Optional: if you want a circular logo */
}

/* "Site Under Construction" Banner */
.under-construction {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: red;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1000;
}